<template>
    <v-dialog :value="value" persistent width="700px">
        <v-card>
            <v-toolbar flat class="accent" color="main" dark>
                <span v-if="editing">
                    <v-toolbar-title>
                        <v-text-field
                        v-model="calendarEvent.period_name"
                        label="Period Name"
                        hide-details="auto"
                        outlined
                        dense
                        class="pt-2"
                        > 
                        </v-text-field>
                    </v-toolbar-title>
                </span>
                <span v-else>
                    <v-toolbar-title>
                        {{calendarEvent.period_name}}
                    </v-toolbar-title>
                </span>
                <v-spacer />
                <v-btn icon @click="swapEdit()">
                    <span v-if="this.editing">
                    <v-icon>mdi-pencil-outline</v-icon>
                    </span>
                    <span v-else>
                        <v-icon>mdi-pencil</v-icon>
                    </span>
                </v-btn>
                <v-btn icon @click="deleting = true">
                    <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                <v-btn icon @click.stop="$emit('update:value', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>    
            </v-toolbar>
            <v-dialog :value="deleting" persistent width="600px">
                    <v-card>
                    <v-toolbar flat class="accent" color="main" dark>
                        <v-toolbar-title>
                            Are You Sure You Want to Delete {{this.calendarEvent.period_name}}?
                        </v-toolbar-title>
                    </v-toolbar>
                        <v-container>
                        <v-row dense class="mx-3">
                            <v-col>
                            </v-col>
                        <v-col>
                            <v-btn text @click="deleting = false">
                                NO
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn text @click="deleteEvent">
                                YES
                            </v-btn>
                        </v-col>
                        <v-col>
                        </v-col>
                        </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>
            <v-container class="ma-0 pa-6">
                <span v-if="this.editing">
                    <v-form ref="form" lazy-validation>
                        <v-row>
                            <v-col>
                                <v-select
                                v-model="calendarEvent.period_interval"
                                label="Period Interval*"
                                :items="intervals"
                                item-text="name"
                                item-value="value">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                v-model="calendarEvent.fiscal_year"
                                label="Fiscal Year*"
                                hide-details="auto"
                                outlined
                                dense>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                v-model="calendarEvent.start_day_of_week"
                                label="Start Day of Week*"
                                required
                                :items="weekdays"
                                item-value="value"
                                item-text="name">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                            <v-menu
                            close-on-content-click
                            transition="scale-transition"
                            offset-y
                            nudge-top="25"
                            max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-on="on"
                                :attrs="attrs"
                                :value="calendarEvent.start_date"
                                label="Start Date"
                                required
                                :rules="!calendarEvent.start_date ? [v => !!v || 'Start Date is required'] : []"
                                prepend-inner-icon="mdi-calendar"
                                validate-on-blur
                                readonly
                                outlined
                                dense>
                                </v-text-field>
                                </template>
                                <v-date-picker
                                v-model="calendarEvent.start_date"
                                no-title>
                                </v-date-picker>
                            </v-menu>
                            </v-col>
                            <v-col>
                            <v-menu
                            close-on-content-click
                            transition="scale-transition"
                            offset-y
                            nudge-top="25"
                            max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-on="on"
                                :attrs="attrs"
                                :value="calendarEvent.end_date"
                                label="End Date"
                                required
                                :rules="!calendarEvent.end_date ? [v => !!v || 'End Date is required'] : []"
                                prepend-inner-icon="mdi-calendar"
                                validate-on-blur
                                readonly
                                outlined
                                dense>
                                </v-text-field>
                                </template>
                                <v-date-picker
                                v-model="calendarEvent.end_date"
                                no-title>
                                </v-date-picker>
                            </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-menu
                                close-on-content-click
                                transition="scale-transition"
                                offset-y
                                nudge-top="25"
                                max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-on="on"
                                    :attrs="attrs"
                                    :value="calendarEvent.finalize_date"
                                    label="Finalize Date"
                                    required
                                    :rules="!calendarEvent.finalize_date ? [v => !!v || 'Finalize Date is required'] : []"
                                    prepend-inner-icon="mdi-calendar"
                                    validate-on-blur
                                    readonly
                                    outlined
                                    dense>
                                    </v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="calendarEvent.finalize_date"
                                    no-title>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </span>
            <span v-else>
            <v-row>
                <v-col>
                    Period Interval: {{this.calendarEvent.period_interval}}
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    Fiscal Year: {{this.calendarEvent.fiscal_year}}
                </v-col>
                <v-col>
                    Start Day of Week: {{this.calendarEvent.start_day_of_week}}
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    Start Date: {{this.calendarEvent.start_date}}
                </v-col>
                <v-col>
                    End Date: {{this.calendarEvent.end_date}}
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    Finalize Date: {{this.calendarEvent.finalize_date}}
                </v-col>
            </v-row>
            </span>
            <span v-if="this.editing">
                <v-row>
                <v-col>
                    <v-btn color="success"
                    @click="updateEvent" 
                    :loading="loading"
                    :disabled="loading">
                    Save
                </v-btn>
                </v-col>
            </v-row>
            </span>
            </v-container>
        </v-card>
        </v-dialog>
</template>
    
<script>
import BillingCalendar from '@/axios/billing-calendar'
import { displayAlert } from '@/mixins/displayAlert'

export default ({
    name: "EventModal",
    
    props: ['value', 'selectedEvent'],

    mixins: [displayAlert],

    data () {
        return {
            calendarEvent: {},
            editing: false,
            deleting: false,
            weekdays: [{'name': 'Monday', 'value': 'monday'}, {'name': 'Tuesday', 'value': 'tuesday'}, {'name': 'Wednesday', 'value': 'wednesday'}, {'name': 'Thursday', 'value': 'thursday'}, 
            {'name': 'Friday', 'value': 'friday'}, {'name': 'Saturday', 'value': 'saturday'}, {'name': 'Sunday', 'value': 'sunday'}],
            intervals: [{'name': 'Weekly', 'value': 'weekly'}, {'name': 'Monthly', 'value': 'monthly'}, {'name': 'Bimonthly', 'value': 'bimonthly'}, {'name': 'Quarterly', 'value': 'quarterly'},
            {'name': 'Annually', 'value': 'annually'}, {'name': 'Biannually', 
            'value': 'biannually'}],
            validParties: [{'name': 'SpartanNash', 'id': 'fd4d2dd3-8a57-4a8d-a104-59afe3f1289d'}],
            loading: false,
            modal: false,
            component: '',
            componentKey: 0,
        }
    },

    created () {
        this.calendarEvent = this._.cloneDeep(this.selectedEvent)
    },

    methods: {
        swapEdit () {
            this.editing = !(this.editing)
        },

        async updateEvent () {
            this.loading = true
            try {
            const res = await BillingCalendar.updateEvent(this.calendarEvent, this.calendarEvent.id)
                if (res?.status === 200) {
                this.emitAlert(true, 'success', 'Successfully Updated Calendar')
                this.$emit('close', true)
                }
            } catch (err) {
                this.handleError(err)
            } finally {
                this.loading = false
                await this.getEvent ()
            }
        },

        async getEvent () {
            this.loading = true
            try {
                const res = await BillingCalendar.getEvent(this.calendarEvent.id)
                if (res.data) {
                    this.calendarEvent = res.data
                }
            } catch (err) {
                this.handleError(err)
            } finally {
                this.loading = false
            }
        },

        async deleteEvent () {
            this.loading = true
            try {
                const res = await BillingCalendar.deleteEvent(this.calendarEvent.id)
                if(res.status == 200) {
                    this.emitAlert(true, 'success', 'Successfully Deleted Billing Period')
                }
            } catch (err){
                this.handleError(err)
            } finally {
                this.loading = false
                this.deleting = false
                this.$emit('update:value', false)
            }
            
        }
    }

})
</script>
